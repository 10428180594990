import { ConsentEvents } from '../events';

export default class CookieConsent {
    #cookieConsentName = 'autowise-cookie-consent';
    #document;
    #consentForm;
    #consentDialog;
    #preferencesDialog;

    constructor(document, consentFormSelector, consentDialogSelector, preferencesDialogSelector) {
        this.#document = document;
        this.#consentForm = document.querySelector(consentFormSelector);
        this.#consentDialog = document.querySelector(consentDialogSelector);
        this.#preferencesDialog = document.querySelector(preferencesDialogSelector);

        if (this.#consentForm === null) {
            throw new Error('Consent form not found');
        }

        if (this.#consentDialog === null) {
            throw new Error('Consent dialog not found');
        }

        if (this.#preferencesDialog === null) {
            throw new Error('Preferences dialog not found');
        }

        this.#bindListener();
    }

    hasGivenConsent() {
        return this.#document.cookie.split(';').some((item) => item.trim().startsWith(this.#cookieConsentName));
    }

    isCookiePolicyPage() {
        const cookiePolicyLink = this.#consentDialog.querySelector('a[data-cookie-policy-link]');

        if (cookiePolicyLink) {
            return window.location.pathname.indexOf(cookiePolicyLink.attributes.href.value) !== -1;
        }

        return false;
    }

    load() {
        const cookie = this.#document.cookie.split(';').find((item) => item.trim().startsWith(this.#cookieConsentName));

        if (cookie === undefined) {
            throw new Error('Cookie not found');
        }

        let categories;

        try {
            categories = JSON.parse(decodeURIComponent(cookie.split('=')[1]));
        } catch {
            categories = this.#getCategories();
        }

        this.#document.dispatchEvent(
            new CustomEvent(ConsentEvents.CONSENT_UPDATED, {
                detail: {
                    ...this.#getCategories(),
                    ...categories,
                },
            }),
        );
    }

    show() {
        // Check if we are running from an embed
        if (window && window !== window.parent) {
            console.info('Consent dialog cannot be activated due to the inability to set a cookie in the parent window.');
            return;
        }

        this.#consentDialog.showModal();
    }

    acceptAll() {
        const categories = this.#getCategories();

        for (const category of Object.keys(categories)) {
            categories[category] = true;
        }

        this.#storeCookie(categories);
        this.#document.dispatchEvent(
            new CustomEvent(ConsentEvents.CONSENT_UPDATED, {
                detail: categories,
            }),
        );

        this.#closeModals();
    }

    denyAll() {
        const categories = this.#getCategories();

        for (const category of Object.keys(categories)) {
            categories[category] = false;
        }

        this.#storeCookie(categories);
        this.#document.dispatchEvent(
            new CustomEvent(ConsentEvents.CONSENT_UPDATED, {
                detail: categories,
            }),
        );

        this.#closeModals();
    }

    savePreferences() {
        const categories = this.#getCategories();
        this.#storeCookie(categories);
        this.#document.dispatchEvent(
            new CustomEvent(ConsentEvents.CONSENT_UPDATED, {
                detail: categories,
            }),
        );

        this.#closeModals();
    }

    #bindListener() {
        this.#consentForm.addEventListener('submit', (e) => {
            e.preventDefault();
            const buttonName = e.submitter.name;

            if (buttonName === 'accept-all') {
                this.acceptAll();
                return;
            }

            if (buttonName === 'deny-all') {
                this.denyAll();
                return;
            }

            if (buttonName === 'modify-cookie-preferences') {
                this.#preferencesDialog.showModal();
                return;
            }

            if (buttonName === 'save-cookie-preferences') {
                this.savePreferences();
                return;
            }

            throw new Error('Unknown button');
        });
    }

    #storeCookie(categories) {
        this.#document.cookie = `${this.#cookieConsentName}=${encodeURIComponent(
            JSON.stringify(categories),
        )}; path=/; secure; max-age=31536000; SameSite=Strict`;
    }

    #getCategories() {
        const categories = {};

        for (const input of this.#document.querySelectorAll('input[name^="cookie_consent["]')) {
            categories[input.name.match(/\[([a-z]+)]/)[1]] = input.checked;
        }

        return categories;
    }

    #closeModals() {
        if (this.#preferencesDialog.open) {
            this.#preferencesDialog.close();
        }

        if (this.#consentDialog.open) {
            this.#consentDialog.close();
        }
    }
}
