import CookieConsent from './consent';
import { ConsentEvents } from '../events';

window.dataLayer = window.dataLayer || [];

const scriptTag = document.querySelector('script[data-autowise-consent-mode][data-gtm-api-key]');

function gtag() {
    // biome-ignore lint/style/noArguments: Recommended by Google Tag Manager
    window.dataLayer.push(arguments);
}

// Load Google Tag Manager
function gtm(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': +new Date(),
        event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
}

if (!scriptTag) {
    throw new Error('Script tag not found');
}

if (scriptTag.dataset.autowiseConsentMode === 'true') {
    gtag('consent', 'default', {
        security_storage: 'granted',
        functional_storage: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
        wait_for_update: 500,
    });

    gtag('set', 'url_passthrough', true);

    const consent = new CookieConsent(
        document,
        '#autowise-cookie-consent-form',
        '#autowise-cookie-consent-dialog',
        '#autowise-cookie-preferences-dialog',
    );

    document.addEventListener(ConsentEvents.CONSENT_UPDATED, (e) => {
        const consentCategories = e.detail;
        const grantedOrDenied = (category) => (consentCategories[category] || false ? 'granted' : 'denied');
        const analytics = grantedOrDenied('analytics');
        const marketing = grantedOrDenied('marketing');
        const functional = grantedOrDenied('functional');

        gtag('consent', 'update', {
            security_storage: 'granted',
            functional_storage: functional,
            ad_storage: marketing,
            ad_user_data: marketing,
            ad_personalization: marketing,
            analytics_storage: analytics,
        });
    });

    if (consent.hasGivenConsent()) {
        consent.load();
    } else if (!consent.isCookiePolicyPage()) {
        // Feels kinda useless to ask a customer for consent on the cookie policy page itself.
        consent.show();
    }
}

gtm(window, document, 'script', 'dataLayer', scriptTag.dataset.gtmApiKey);
