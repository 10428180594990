const ConsentEvents = {
    CONSENT_UPDATED: 'AutoWise.consentUpdated',
};

const EmbedEvents = {
    INITIALIZE: 'AutoWise.initialize',
    INITIALIZED: 'AutoWise.initialized',
    PAGE_TITLE_UPDATED: 'AutoWise.pageTitleUpdated',
    EMBED_PAGE_LOADED: 'AutoWise.pageLoaded',
    STYLESHEETS_LOADED: 'AutoWise.stylesheetsLoaded',
    JAVASCRIPT_LOADED: 'AutoWise.javascriptLoaded',
    MAIN_CONTENT_UPDATED: 'AutoWise.mainContentUpdated',
    /* Overrides the HREFs of the vehicle list links */
    VEHICLE_LIST_LINKS_BOUND: 'AutoWise.vehicleListLinksBound',
    VEHICLE_LIST_PAGE_LOADED: 'AutoWise.vehicleListPageLoaded',
    /* Handle submit of the javascript forms */
    VEHICLE_DETAIL_PAGE_FORM_BOUND: 'AutoWise.vehicleDetailPageFormBound',
    /* When a user submits a form that we have previously bound with VEHICLE_DETAIL_PAGE_FORM_BOUND */
    VEHICLE_DETAIL_PAGE_FORM_SUBMITTED: 'AutoWise.vehicleDetailPageFormSubmitted',
    FINANCIAL_LEASE_AMOUNT_CALCULATED: 'AutoWise.financialLeaseAmountCalculated',
};

const StockEvents = {
    LIST_PAGE_LOADED: 'AutoWise.vehicleListPageLoaded',
    DETAIL_PAGE_LOADED: 'AutoWise.vehicleDetailPageLoaded',
    DETAIL_PAGE_COMPONENTS: {
        SWIPER_INITIALIZED: 'AutoWise.vehicleDetailPageSwiperInitialized',
    },
};

export { ConsentEvents, EmbedEvents, StockEvents };
